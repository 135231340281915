import React, { useState } from 'react';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import './fonts/stylesheet.css';
import './App.css';
import PhoneInputMask from './InputMask';
import axios from 'axios';

interface AppealFormInterface {
  fullname: string,
  phone: string
}


function App() {

  const [appealForm, setAppealForm] = useState<AppealFormInterface>({
    fullname: '',
    phone: ''
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === 'fullname') {
      setAppealForm((prev) => {
        return {
          ...prev,
          fullname: value
        }
      })
    } else {
      let cleanValue = value.replace(/\D+/g, '');
      let formattedValue = '+7 ';

      if (cleanValue.startsWith('7')) {
        cleanValue = cleanValue.slice(1);
      }

      if (cleanValue.length > 0) {
        formattedValue += '(' + cleanValue.substring(0, 3);
      }
      if (cleanValue.length >= 4) {
        formattedValue += ') ' + cleanValue.substring(3, 6);
      }
      if (cleanValue.length >= 7) {
        formattedValue += '-' + cleanValue.substring(6, 8);
      }
      if (cleanValue.length >= 9) {
        formattedValue += '-' + cleanValue.substring(8, 10);
      }

      setAppealForm((prev) => {
        return {
          ...prev,
          phone: formattedValue
        }
      })
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (extractDigits(appealForm.phone).length !== 11) {
      alert('Необходимо ввести полный номер телефона')
    } else {
      try {
        await axios.post('https://api.pddtest.kz/crm/public/create_card/190f21084af1934057_landing/tiktok', {
          ...appealForm,
          phone: extractDigits(appealForm.phone)
        });
        alert('Благодарим за заявку. В ближайшее время с Вами свяжется наш специалист.');
        cleanForm();
      } catch (e: any) {
        alert(e.message ? e.message : 'Произошла ошибка')
      }
    }
  }

  function cleanForm() {
    setAppealForm({
      fullname: '',
      phone: ''
    })
  }

  function extractDigits(phoneNumber: string) : string {
    return phoneNumber.replace(/\D/g, '');
  }


  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        background: '#224FD6'
      }}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
    >
      <Stack
        flexDirection={'column'}
        className={'stack-container'}
      >

        <Stack
          flexDirection={'row'}
          sx={{
            width: '100%',
            pt: '50px'
          }}
          justifyContent={'center'}
        >
          <img
            src={'/logo.png'}
            alt={'Логотип PDDTest.KZ'}
            className={'logo'}
          />
        </Stack>
        <Stack
          flexDirection={'row'}
          justifyContent={'center'}
          sx={{
            mt: 5,
            width: '100%',
            textAlign: 'left'
          }}
        >
          <Typography
            sx={{
              color: 'white',
              fontWeight: 600,
              fontFamily: "'TT Travels', sans-serif",
              width: '100%',
              textAlign: 'left'
            }}
            className={'text'}
          >
            Оставить заявку
          </Typography>
        </Stack>

        <Stack
          flexDirection={'column'}
          alignItems={'center'}
          gap={'26px'}
          sx={{
            my: '49px',
            width: '100%'
          }}
        >
          <form
            style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '26px'}}
            onSubmit={handleSubmit}
          >
            <TextField
              name={'fullname'}
              placeholder={'Введите ваше имя *'}
              sx={{
                border: '1px solid white',
                borderRadius: '12px',
                width: '100%',
              }}
              type={'text'}
              value={appealForm.fullname}
              onChange={handleChange}
              required={true}
            />
            <PhoneInputMask
              value={appealForm.phone}
              handleChange={handleChange}
            />
            <Button
              variant={'contained'}
              className={'button'}
              type={'submit'}
            >
              <span>
                Отправить
              </span>
            </Button>
          </form>
        </Stack>
      </Stack>

      <img
        src={'/background.png'}
        alt={'Фон'}
        className={'background'}
      />
    </Box>
  );
}

export default App;
